import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../molecules/layout'
import { Wrapper, Billboard, SEO } from '@renderbus/common/components'
import {
  BannerContainer,
  BannerButton,
  Container,
  Section,
  RulesContainer,
  SubTitle,
  ActivityTime,
  SectionTitle,
  Img,
  Item,
  CouponCard,
  PriceWrapper,
  Price,
  PriceContainer,
  CardButton,
  Badge,
} from './2020-1111.atom'

import Double11Title from '../images/index/2020-1111-title.png'
import Title1 from '../images/2020-1111/title1.png'
import Title2 from '../images/2020-1111/title2.png'
import Title3 from '../images/2020-1111/title3.png'
import Icon1 from '../images/2020-1111/icon1.png'
import Icon2 from '../images/2020-1111/icon2.png'
import Icon3 from '../images/2020-1111/icon3.png'
import Icon4 from '../images/2020-1111/icon4.png'
import Top from '../images/2020-1111/top.png'

const couponList = [
  {
    subTip: '充值300元送',
    price: '150',
  },
  {
    subTip: '充值800元送',
    price: '500',
  },
  {
    subTip: '充值2000元以上送',
    tip: '充多少送多少',
  },
]
class DoubleEleven extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='2020双十一云渲染充值大促-Renderbus云渲染农场'
          keywords='云渲染,云渲染优惠活动,渲染农场充值优惠'
          description='Renderbus云渲染农场充值优惠活动,双十一大促,劲爆低价,效果图用户最高享充多少送多少,活动时间2020年11月09日-11月22日.'
        />
        <BannerContainer>
          <SubTitle>效果图用户最高享充多少送多少</SubTitle>
          <Img src={Double11Title} alt='双11大促 劲爆低价' />
          <BannerButton
            onMouseDown={() => {
              window.open('https://task.renderbus.com/center/user/payment', '_blank')
            }}
          >
            立即充值
          </BannerButton>
          <ActivityTime>活动时间：2020.11.09-11.30</ActivityTime>
          <Billboard
            fluid={[
              data.bannerMobile.childImageSharp.fluid,
              {
                ...data.banner.childImageSharp.fluid,
                media: `(min-width: 780px)`,
              },
            ]}
          />
        </BannerContainer>
        <Wrapper style={{ margin: '70px auto' }}>
          <Container>
            <SectionTitle src={Title1} alt='双十一大促' />
            <Section>
              {couponList.map((coupon, index) => (
                <CouponCard key={index}>
                  <p>{coupon.subTip}</p>
                  <PriceWrapper>
                    {coupon.price ? (
                      <PriceContainer>
                        <span>¥</span>
                        <Price>{coupon.price}</Price>
                      </PriceContainer>
                    ) : (
                      <p>{coupon.tip}</p>
                    )}
                    <span>渲染券</span>
                  </PriceWrapper>
                  <CardButton
                    onMouseDown={() => {
                      window.open('https://task.renderbus.com/center/user/payment', '_blank')
                    }}
                  >
                    立即充值
                  </CardButton>
                  {index === couponList.length - 1 && <Badge src={Top} alt='推荐' />}
                </CouponCard>
              ))}
            </Section>
          </Container>
          <Container>
            <SectionTitle src={Title2} alt='参与方式' />
            <Section>
              <Item>
                <img src={Icon1} alt='login' />
                <span>登录账户</span>
              </Item>
              <Item>
                <img src={Icon2} alt='recarge' />
                <span>充值</span>
              </Item>
              <Item>
                <img src={Icon3} alt='coupon' />
                <span>优惠券到账</span>
              </Item>
              <Item>
                <img src={Icon4} alt='call' />
                <span>联系客服</span>
              </Item>
            </Section>
          </Container>
          <Container>
            <SectionTitle src={Title3} alt='活动规则' />
            <RulesContainer>
              <li>
                一次性充值300赠送150渲染券、一次性充值800赠送500渲染券、充值2000以上充多少送多少，上不封顶；
              </li>
              <li>用户充值后，赠送渲染券直接到账，若5分钟内未到账，请咨询客服；</li>
              <li>赠送金额不可申请发票、提现，该优惠不与其他充值优惠活动同享；</li>
              <li>本次活动所充值金额仅限在效果图渲染使用，不支持退款和提现；</li>
              <li>如有任何问题，可以联系瑞云客服，此次活动数量有限，先到先得；</li>
              <li>
                本次活动的最终解释权归瑞云科技所有，瑞云有权对恶意刷抢活动资源，利用资源从事违法违规行为的用户收回活动参与资格，并进行封号处理
              </li>
            </RulesContainer>
          </Container>
        </Wrapper>
      </Layout>
    )
  }
}

export default DoubleEleven

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/promotion/banner-2020-1111.png/" }) {
      ...fluidImage
    }
    bannerMobile: file(relativePath: { regex: "/index/2020-1111-banner-mb.png/" }) {
      ...fluidImage
    }
  }
`
